<script lang="ts">
export const enum BadgeType {
  BWARE,
  SALE,
  IS_SET,
  IS_ACTION,
  MORE_VARIANTS,
}
</script>

<script setup lang="ts">
const props = defineProps<{
  badgeType: BadgeType;
  text: string;
}>();

const badgeData = computed<BadgeData | undefined>(() => {
  switch (props.badgeType) {
    case BadgeType.SALE:
      return {
        text: `-${props.text || ""}`,
        classes:
          "c-scheppach-error-700 bg-scheppach-error-50 b b-scheppach-error-700",
      };

    case BadgeType.BWARE:
    case BadgeType.IS_SET:
    case BadgeType.MORE_VARIANTS:
      return {
        text: `${props.text || ""}`,
        classes:
          "c-scheppach-primary-500 bg-scheppach-primary-50 b b-scheppach-primary-500",
      };
    case BadgeType.IS_ACTION:
      return {
        text: `${props.text || ""}`,
        classes:
          "c-scheppach-neutral-50 bg-scheppach-neutral-700 b b-scheppach-neutral-700",
      };
  }
});
</script>

<template>
  <div
    class="text-sm font-bold rounded flex items-center justify-center px-3 p-1"
    :class="badgeData?.classes"
  >
    {{ badgeData?.text }}
  </div>
</template>
